<template>
  <p>Logging out...</p>
</template>

<script>
import { auth } from "../../firebaseDb";

export default {
  created() {
    this.$store.dispatch("unsubscribe");
  },
  mounted() {
    auth.signOut().then(() => {
      // not sure why this causes an error when logging out
      this.$store.dispatch("clearData");
      this.$router.push("/login");
    });
  },
};
</script>